<template>
  <data-table
    :headers="headers"
    :items="items"
    :options.sync="options"
    :total="items.length"
  >
    <template v-slot:[`item.status`]="{ item }">
      {{ $t(item.status) }}
    </template>

    <template v-slot:[`item.title`]="{ item }">
      <report-job-filter-dialog :title="item.title" :items="item.filter" />
    </template>

    <template v-slot:[`item.start_at`]="{ item }">
      {{ parseDate(item.start_at) }}
    </template>

    <template #[`item.end_at`]="{ item }">
      {{ parseDate(item.end_at) }}
    </template>

    <template #[`item.memory_usage`]="{ item }">
      {{ parseMemory(item.memory_usage) }}
    </template>

    <template #[`item.working_time`]="{ item }">
      {{ parseWorkingTime(item.working_time) }}
    </template>

    <template #[`item.actions`]="{ item }">
      <report-job-module-actions
        :item="item"
        :value="item.id"
        @cancel="cancelItem"
        @download="downloadItem"
      />
    </template>

    <template #[`item.company_id`]="{ item }">
      <company-preview-by-id :company-id="item.company_id" />
    </template>
  </data-table>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import type { DataOptions, DataTableHeader } from "vuetify";
import { ReportJob, type ReportJobData } from "@planetadeleste/vue-mc-gw";
import { find, map, set } from "lodash";
import dayjs from "dayjs";
import ReportJobModuleActions from "../components/ReportJobModuleActions.vue";
import Utils from "@/services/Utils";
import ReportJobMixin from "../mixins/ReportJobMixin";
import { AuthModule } from "@/store/auth";
import CompanyPreviewById from "../components/CompanyPreviewById.vue";
import { usePusher } from "@/services/pusher";
import { type Channel } from "pusher-js";
import type { AxiosRequestConfig } from "axios";
import { route } from "@/services/laroute";
import ReportJobFilterDialog from "../components/ReportJobFilterDialog.vue";

@Component({
  components: {
    ReportJobModuleActions,
    CompanyPreviewById,
    ReportJobFilterDialog,
  },
})
export default class ReportJobList extends Mixins(ReportJobMixin) {
  obPusher = usePusher();
  obReportJobWaitingChannel: Channel | null = null;
  obReportJobWorkingChannel: Channel | null = null;
  obReportJobCompletedChannel: Channel | null = null;
  obReportJobFailedChannel: Channel | null = null;
  obReportJobCanceledChannel: Channel | null = null;
  iForceRequestUpdate = 1;
  isList = true;
  states = ["waiting", "working", "completed", "failed", "canceled"];
  filterItems: Record<string, any> = {};

  options: DataOptions = {
    page: 1,
    itemsPerPage: 20,
    sortBy: [],
    sortDesc: [false],
    groupBy: [],
    groupDesc: [false],
    multiSort: false,
    mustSort: false,
  };

  get userIsAdmin() {
    return AuthModule.isAdmin;
  }

  get items() {
    return map<Partial<ReportJobData>>(
      this.obCollection.getModelList(),
      (obItem: ReportJobData) => {
        const arClass: string[] = ["striped", "lighten-5"];

        switch (obItem.status) {
          case "waiting":
            arClass.push("gray");
            break;
          case "working":
            arClass.push("orange");
            break;
          case "completed":
            arClass.push("green");
            break;
          case "failed":
            arClass.push("red");
            break;
        }

        set(obItem, "css", arClass.join(" "));

        return obItem;
      }
    );
  }

  onMounted() {
    if (this.$route.query.msg) {
      this.$toast.success(
        "Estamos procesando tu reporte\nen breve lo podras descargar aquí"
      );
    }
    const arHeaders: DataTableHeader[] = [
      { text: this.$t("status") as string, value: "status" },
      { text: this.$t("title") as string, value: "title" },
      { text: this.$t("start.date") as string, value: "start_at" },
      { text: this.$t("actions") as string, value: "actions" },
    ];

    if (this.userIsAdmin) {
      arHeaders.splice(0, 0, {
        text: this.$t("job") as string,
        value: "job_id",
      });
      arHeaders.splice(1, 0, {
        text: this.$t("company") as string,
        value: "company_id",
      });
      arHeaders.splice(3, 0, {
        text: this.$t("queue") as string,
        value: "queue",
      });
      arHeaders.splice(5, 0, {
        text: this.$t("end.date") as string,
        value: "end_at",
      });
      arHeaders.splice(6, 0, {
        text: this.$t("memory.usage") as string,
        value: "memory_usage",
      });
      arHeaders.splice(7, 0, {
        text: this.$t("working.time") as string,
        value: "working_time",
      });
    }
    this.delDTHeader(["name", "active"]);
    this.addDTHeaders(arHeaders);
  }

  parseDate(date: string) {
    return dayjs(date).format("L");
  }

  parseMemory(memory: string) {
    const kilobytes = parseInt(memory, 10);
    if (isNaN(kilobytes)) return memory;

    const units = ["KB", "MB", "GB", "TB", "PB"];
    let unitIndex = 0;

    let value = kilobytes;
    while (value >= 1024 && unitIndex < units.length - 1) {
      value /= 1024;
      unitIndex++;
    }

    return `${value.toFixed(1)} ${units[unitIndex]}`;
  }

  parseWorkingTime(time: string) {
    const milliseconds = parseInt(time, 10);
    if (isNaN(milliseconds)) return time;

    const seconds = Math.floor((milliseconds / 1000) % 60);
    const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

    let result = "";
    if (hours > 0) {
      result += `${hours}h `;
    }
    if (minutes > 0 || hours > 0) {
      result += `${minutes}m `;
    }
    result += `${seconds}s`;

    return result.trim();
  }

  onBeforeIndex(obFilters: Record<string, any>) {
    set(obFilters, "canceled", 0);
    set(obFilters, "completed", 1);
    set(obFilters, "failed", 0);
    set(obFilters, "waiting", 1);
    set(obFilters, "working", 1);
    set(obFilters, "forceRequest", this.iForceRequestUpdate++);
  }

  async cancelItem(item: ReportJobData) {
    const obModel = new ReportJob({ id: item.id });
    await obModel.cancel();
  }

  async downloadItem(item: any) {
    /*
    const obResponse = await obModel.download();
    const blob = obResponse.response ? obResponse.response.data : null;
    Utils.downloadBlob(blob, `${"test"}.pdf`);
    */

    const obModel = new ReportJob({ id: item.id });
    const content = await this.loadContent(obModel);
    Utils.downloadBlob(content, `${item.title}.pdf`);
  }

  async loadContent(obModel: ReportJob) {
    const obData: Record<string, any> = { id: obModel.id };
    const obConfig: AxiosRequestConfig = {
      url: route("reportjobs.download", obData),
      method: "GET",
      data: obData,
      responseType: "blob",
    };
    const obResponse = await obModel.createRequest(obConfig).send();
    return obResponse.response ? obResponse.response.data : null;
  }

  async onLoadIndex(sValue: string) {
    if (sValue !== this.sModelName) {
      return;
    }
    this.index();
  }

  onRegisterEvents() {
    this.addEvent("filters.change", this.index);
    this.addEvent("model.mounted", this.onLoadIndex);
  }

  setFilterItems(obData: any) {
    this.filterItems = obData.filter;
    (
      this.$refs.filterDialog as InstanceType<typeof ReportJobFilterDialog>
    ).openDialog();
  }

  getFilterItems() {
    return this.filterItems;
  }

  setItem(obData: any) {
    const item = find(this.items, { id: obData.id });

    if (item) {
      Object.assign(item, obData.data);
    }
  }

  addItem(obData: any) {
    //TODO agregar item nuevo al collection
    this.obCollection.add(new ReportJob(obData.data));
  }

  onGetReportJobWaitingMessage(obData: any) {
    this.addItem(obData);
  }

  onGetReportJobWorkingMessage(obData: any) {
    this.setItem(obData);
  }

  onGetReportJobCompletedMessage(obData: any) {
    this.setItem(obData);
  }

  onGetReportJobFailedMessage(obData: any) {
    this.setItem(obData);
  }

  onGetReportJobCanceledMessage(obData: any) {
    this.setItem(obData);
  }

  created() {
    this.deleteChannelIfExist();
    this.obReportJobWaitingChannel = this.obPusher.channel(
      "reportjob",
      "waiting",
      this.onGetReportJobWaitingMessage
    );
    this.obReportJobWorkingChannel = this.obPusher.channel(
      "reportjob",
      "working",
      this.onGetReportJobWorkingMessage
    );
    this.obReportJobCompletedChannel = this.obPusher.channel(
      "reportjob",
      "completed",
      this.onGetReportJobCompletedMessage
    );
    this.obReportJobFailedChannel = this.obPusher.channel(
      "reportjob",
      "failed",
      this.onGetReportJobFailedMessage
    );
    this.obReportJobCanceledChannel = this.obPusher.channel(
      "reportjob",
      "canceled",
      this.onGetReportJobCanceledMessage
    );
  }

  deleteChannelIfExist() {
    if (this.obReportJobWaitingChannel) {
      this.obReportJobWaitingChannel.disconnect();
    }
    if (this.obReportJobWorkingChannel) {
      this.obReportJobWorkingChannel.disconnect();
    }
    if (this.obReportJobCompletedChannel) {
      this.obReportJobCompletedChannel.disconnect();
    }
    if (this.obReportJobFailedChannel) {
      this.obReportJobFailedChannel.disconnect();
    }
    if (this.obReportJobCanceledChannel) {
      this.obReportJobCanceledChannel.disconnect();
    }
  }

  beforeDestroy() {
    this.deleteChannelIfExist();
  }
}
</script>
